import revive_payload_client_Q3y2W6AinH from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__c2zkhrmyfn367omvq6nhxqrbki/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DO89TdrNrJ from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__c2zkhrmyfn367omvq6nhxqrbki/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ikXJAJdYDZ from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__c2zkhrmyfn367omvq6nhxqrbki/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_qN0R89fHEV from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__c2zkhrmyfn367omvq6nhxqrbki/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GzD78BTKTW from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__c2zkhrmyfn367omvq6nhxqrbki/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_cseQcO2rmD from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__c2zkhrmyfn367omvq6nhxqrbki/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fGemMPBcLu from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__c2zkhrmyfn367omvq6nhxqrbki/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_mvj85xSm4B from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_sRq2qlEaVa from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__c2zkhrmyfn367omvq6nhxqrbki/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_GUD2l5c5BV from "/app/node_modules/.pnpm/vue-recaptcha@3.0.0-alpha.6_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/vue-recaptcha/dist/nuxt-plugin.mjs";
import plugin_t50wJp7T7T from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node_ngedvfiwqsqqn7ecv26i5qdage/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_R9HL2zBGYn from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import gsap_FnwzRby0Oe from "/app/src/plugins/gsap.ts";
import emitter_5zAQVt1oGs from "/app/src/plugins/emitter.ts";
import init_navigation_Ny4GeHzG54 from "/app/src/plugins/init-navigation.ts";
import lottie_jvNkrOddXP from "/app/src/plugins/lottie.ts";
import sentry_h0fMjCG9AB from "/app/src/plugins/sentry.ts";
import social_share_dmDUvjWlhi from "/app/src/plugins/social-share.ts";
import vee_validate_fgGHHmjH3T from "/app/src/plugins/vee-validate.ts";
import vuetify_F4zXYcNIwC from "/app/src/plugins/vuetify.ts";
export default [
  revive_payload_client_Q3y2W6AinH,
  unhead_DO89TdrNrJ,
  router_ikXJAJdYDZ,
  payload_client_qN0R89fHEV,
  navigation_repaint_client_GzD78BTKTW,
  check_outdated_build_client_cseQcO2rmD,
  chunk_reload_client_fGemMPBcLu,
  plugin_vue3_mvj85xSm4B,
  components_plugin_KR1HBZs4kY,
  prefetch_client_sRq2qlEaVa,
  nuxt_plugin_GUD2l5c5BV,
  plugin_t50wJp7T7T,
  i18n_R9HL2zBGYn,
  gsap_FnwzRby0Oe,
  emitter_5zAQVt1oGs,
  init_navigation_Ny4GeHzG54,
  lottie_jvNkrOddXP,
  sentry_h0fMjCG9AB,
  social_share_dmDUvjWlhi,
  vee_validate_fgGHHmjH3T,
  vuetify_F4zXYcNIwC
]