import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "139",
  height: "113",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"#004A98\" clip-path=\"url(#a)\" opacity=\".75\"><path d=\"m55.72 78.571-40.53 21.433-1.433 2.713 15.756 3.88L66.927 87.23 55.72 78.57Zm21.343-34.727L68.14 0l15.755 3.88 8.138 41.21-14.969-1.246ZM139 46.872 97.651 71.317l8.5 41.683-15.74-3.896-2.062-1.687-8.39-40.453 41.837-21.527z\"></path><path d=\"m65.636 17.395 5.21 25.47.897 4.337 4.423.363 26.758 2.24-23.799 12.317-3.982 2.066.897 4.4 5.178 25.36L59.623 76.3l-3.022-2.46-3.447 1.75-25.279 13.075 11.9-22.443 2.267-4.305-3.762-3.154-19.691-16.071 28.332 2.35 3.903.331 1.826-3.47 13.033-24.618M68.186-.063l-20.809 39.38L0 35.358l34.533 28.2-20.776 39.238 42.12-21.812 34.534 28.199-8.516-41.667 42.121-21.89-47.362-3.943L68.138 0z\"></path></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h139v113H0z\"></path></clipPath></defs>", 2)
  ])))
}
export default { render: render }