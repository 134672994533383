
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93XOh4cis8EcMeta } from "/app/src/pages/[...slug].vue?macro=true";
import { default as _91product_93_45campaignpz5CdX4rZCMeta } from "/app/src/pages/[product]-campaign.vue?macro=true";
import { default as index6dle6k33gZMeta } from "/app/src/pages/about-us/index.vue?macro=true";
import { default as contactmqHVTjI8koMeta } from "/app/src/pages/contact.vue?macro=true";
import { default as cookiesljJJ99na9gMeta } from "/app/src/pages/cookies.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91slug_93hwEhpzXwWtMeta } from "/app/src/pages/inspiration/[slug].vue?macro=true";
import { default as indexwWiL1Z4orWMeta } from "/app/src/pages/inspiration/index.vue?macro=true";
import { default as _91slug_93D9p4qGXTBQMeta } from "/app/src/pages/products/[slug].vue?macro=true";
import { default as indexwnrgOfBd2dMeta } from "/app/src/pages/products/index.vue?macro=true";
import { default as _91slug_93bOuhFHXO95Meta } from "/app/src/pages/recipes/[slug].vue?macro=true";
import { default as shop_45finderbA7qcl6TxnMeta } from "/app/src/pages/shop-finder.vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/en/:country/:slug(.*)*",
    component: () => import("/app/src/pages/[...slug].vue")
  },
  {
    name: "slug___ar",
    path: "/ar/:country/:slug(.*)*",
    component: () => import("/app/src/pages/[...slug].vue")
  },
  {
    name: "product-campaign___en",
    path: "/en/:country/:product()-campaign",
    meta: _91product_93_45campaignpz5CdX4rZCMeta || {},
    component: () => import("/app/src/pages/[product]-campaign.vue")
  },
  {
    name: "product-campaign___ar",
    path: "/ar/:country/:product()-campaign",
    meta: _91product_93_45campaignpz5CdX4rZCMeta || {},
    component: () => import("/app/src/pages/[product]-campaign.vue")
  },
  {
    name: "about-us___en",
    path: "/en/:country/about-us",
    component: () => import("/app/src/pages/about-us/index.vue")
  },
  {
    name: "about-us___ar",
    path: "/ar/:country/about-us",
    component: () => import("/app/src/pages/about-us/index.vue")
  },
  {
    name: "contact___en",
    path: "/en/:country/contact",
    component: () => import("/app/src/pages/contact.vue")
  },
  {
    name: "contact___ar",
    path: "/ar/:country/contact",
    component: () => import("/app/src/pages/contact.vue")
  },
  {
    name: "cookies___en",
    path: "/en/:country/cookies",
    component: () => import("/app/src/pages/cookies.vue")
  },
  {
    name: "cookies___ar",
    path: "/ar/:country/cookies",
    component: () => import("/app/src/pages/cookies.vue")
  },
  {
    name: "index___en",
    path: "/en/:country",
    component: () => import("/app/src/pages/index.vue")
  },
  {
    name: "index___ar",
    path: "/ar/:country",
    component: () => import("/app/src/pages/index.vue")
  },
  {
    name: "inspiration-slug___en",
    path: "/en/:country/inspiration/:slug()",
    component: () => import("/app/src/pages/inspiration/[slug].vue")
  },
  {
    name: "inspiration-slug___ar",
    path: "/ar/:country/inspiration/:slug()",
    component: () => import("/app/src/pages/inspiration/[slug].vue")
  },
  {
    name: "inspiration___en",
    path: "/en/:country/inspiration",
    component: () => import("/app/src/pages/inspiration/index.vue")
  },
  {
    name: "inspiration___ar",
    path: "/ar/:country/inspiration",
    component: () => import("/app/src/pages/inspiration/index.vue")
  },
  {
    name: "products-slug___en",
    path: "/en/:country/products/:slug()",
    component: () => import("/app/src/pages/products/[slug].vue")
  },
  {
    name: "products-slug___ar",
    path: "/ar/:country/products/:slug()",
    component: () => import("/app/src/pages/products/[slug].vue")
  },
  {
    name: "products___en",
    path: "/en/:country/products",
    component: () => import("/app/src/pages/products/index.vue")
  },
  {
    name: "products___ar",
    path: "/ar/:country/products",
    component: () => import("/app/src/pages/products/index.vue")
  },
  {
    name: "recipes-slug___en",
    path: "/en/:country/recipes/:slug()",
    component: () => import("/app/src/pages/recipes/[slug].vue")
  },
  {
    name: "recipes-slug___ar",
    path: "/ar/:country/recipes/:slug()",
    component: () => import("/app/src/pages/recipes/[slug].vue")
  },
  {
    name: "shop-finder___en",
    path: "/en/:country/shop-finder",
    meta: shop_45finderbA7qcl6TxnMeta || {},
    component: () => import("/app/src/pages/shop-finder.vue")
  },
  {
    name: "shop-finder___ar",
    path: "/ar/:country/shop-finder",
    meta: shop_45finderbA7qcl6TxnMeta || {},
    component: () => import("/app/src/pages/shop-finder.vue")
  }
]