import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "2254",
  height: "2705",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#000",
      "fill-opacity": ".2",
      d: "M1037.95 639.182c50.23-22.314 76.31-68.547 121.81-99.334 49.04-33.207 202.21-126.12 241.84-170.09 86.99-96.518 122.96-144.883 148.09-241.243-116.12-27.919-243.79-54.706-350.54-1.131-62.44 31.365-108.91 86.387-159.94 134.199-88.381 82.703-194.029 145.856-306.229 191.247-90.912 36.839-186.429 62.231-274.736 104.885S286.788 663.733 246.318 753.067c-69.651 153.671 3.368 335.443-29.076 501.033 1.842-14.76 69.151-42.1 81.703-54.23 21.418-20.58 37.206-49.65 55.547-73.07 41.733-53.34 46.022-138.753 93.754-186.828 96.175-96.807 182.719-166.959 360.491-219.349 73.598-21.683 158.958-50.233 229.213-81.441",
      class: "wave wave--top",
      style: {"mix-blend-mode":"multiply"}
    }, null, -1),
    _createElementVNode("path", {
      fill: "#000",
      "fill-opacity": ".5",
      d: "M1249.43 1078.05c-215.24 245.14-350.57 548.14-514.37 830.25-57.889 99.54-120.12 196.72-190.35 288-54.706 71.05-114.279 138.75-180.062 199.98a3554 3554 0 0 1-218.242 308.66c103.201-3.58 206.401-7.68 309.575-11.52 60.916-2.27 154.012 14.18 192.087-45.55 41.391-64.97 56.705-160.2 84.597-232.35 31.76-82.15 249.976-308.61 287.105-388.47 148.22-318.16 135.96-568.71 362.07-837.16 127.43-151.33 285.34-195.594 434.17-325.925 108.7-95.176 224.34-186.326 304.13-306.763 77.25-116.648 115.12-251.742 132.88-391.519-265.82 359.653-704.59 571.951-1003.59 912.367",
      opacity: ".7"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#000",
      "fill-opacity": ".6",
      d: "M989.008 1171.68C1228.3 849.782 1600.24 644.431 1832.61 317.485c60.99-85.861 111.6-179.142 178.19-260.714a680 680 0 0 1 49.76-54.68c-12.26-1-24.55-1.684-36.84-1.947-102.17-2.368-208.98 24.287-283.94 93.755-74.97 69.468-108.99 170.749-169.14 252.9-139.86 190.799-382.7 269.766-585.315 391.756C678.539 923.197 588.31 1140.57 476.742 1366.63c-105.568 213.91 0 513.38-429.984 608.71-66.967 14.85-72.862 611.9 54.784 604.24 37.391-2.23 93.438-50.73 123.672-71.36a1179 1179 0 0 0 117.647-92.1 1269 1269 0 0 0 21.813-19.86 3554 3554 0 0 0 220.11-405.23c127.909-277.27 222.137-574.4 404.224-819.35",
      opacity: ".7"
    }, null, -1)
  ])))
}
export default { render: render }